import React from "react"

function LoadingComponent() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        fontSize: "18px",
      }}
    >
      Loading...
    </div>
  )
}

export default LoadingComponent
