import React, { lazy, Suspense } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import { Grid } from "@mui/material"
import { Helmet } from "react-helmet"

import "./style/column.scss"
import "./style/index.scss"
import "./style/homepage.scss"
import LoadingComponent from "../components/LoadingComponent/LoadingComponent"

const MainSection = lazy(() => import("../components/MainSection/MainSection"))
const MobileMainSection = lazy(() =>
  import("../components/MainSection/MobileMainSection")
)
const TrainingSection = lazy(() =>
  import("../components/TrainingSection/TrainingSection")
)
const UseCasesSection = lazy(() =>
  import("../components/UseCases/UseCasesSection/UseCasesSection")
)
const SurveySection = lazy(() => import("../components/Survey/Survey"))

function IndexPage({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Cassandra Link - Your Source for Cassandra Resources</title>
        <meta
          property="og:title"
          content="Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta
          name="description"
          content="Homepage of Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta
          property="og:description"
          content="Homepage of Cassandra Link - Your Source for Cassandra Resources"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link - Your Source for Cassandra Resources",
            keywords: "Cassandra Link, Cassandra Resources, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={"Cassandra Link"} />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <Grid>
        <Suspense fallback={<LoadingComponent />}>
          <Grid sx={{ display: { xs: "none", sm: "flex" } }} item xs={12}>
            <MainSection data={data} />
          </Grid>
          <Grid sx={{ display: { xs: "flex", sm: "none" } }} item xs={12}>
            <MobileMainSection data={data} />
          </Grid>
          <Grid item xs={12}>
            <TrainingSection />
          </Grid>
          <Grid item xs={12}>
            <UseCasesSection />
          </Grid>
          <Grid item xs={12}>
            <SurveySection />
          </Grid>
        </Suspense>
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery($skip: Int) {
    wallabagList: allCassandraLinks(
      filter: { content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
      limit: 9
      skip: $skip
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        id
        wallabag_created_at
        published_at
        language
        reading_time
        domain_name
        preview_picture
        tags
        content
        description
      }
    }
    tagList: allCassandraLinks(filter: { content: { ne: null } }) {
      nodes {
        title
        tags
      }
    }
    videoList: allCassandraLinks(
      filter: { domain_name: { regex: "/youtube/" }, content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        wallabag_created_at
        published_at
        language
        reading_time
        domain_name
        preview_picture
        tags
      }
    }
    openSourceList: allCassandraLinks(
      filter: { domain_name: { regex: "/github.com/" }, content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
    ) {
      nodes {
        published_by
        alternative_id
        title
        origin_url
        url
        wallabag_created_at
        published_at
        language
        reading_time
        domain_name
        preview_picture
        tags
      }
    }
    newsFeeds: allFeedTtrs {
      nodes {
        link
        title
        pubDate
        id
        author
        contentSnippet
      }
    }
    TopTags: allCassandraLinks(
      filter: { content: { ne: null } }
      sort: { fields: [wallabag_created_at], order: [DESC, DESC] }
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        id
        wallabag_created_at
        published_at
        language
        reading_time
        domain_name
        preview_picture
        content
        description
        tags
      }
    }
  }
`
